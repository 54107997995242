.home h2 {
    margin-bottom: 30px;
    position: relative;
    text-transform: uppercase;
}

.home .row {
    padding: 45px 75px;
}

.home .row.header h3 {

}

.home .row .column h6 {
    margin-bottom: 0px;
}

.home .row.cityBg {
    background-image: url('../images/home_getintouch.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
}

.home .owl-carousel .owl-slide {
    /*opacity: .2;*/
}

.home .owl-carousel div.owl--text .white {
    /*color: #000;*/
}

.home .row.cityBg .three {
    position: relative;
}

.row ul {
    float: left;
    width: 33%;
}

.row ul li {
    margin-bottom: 25px;
}

.jobs li {
    color: #51c1e4;
    font-size: 18px;
    text-transform: uppercase;
}

/*.jobs li::after {*/
    /*content: ' + ';*/
/*}*/

.home .row.cityBg a i {
    margin-bottom: 15px;
}

.home .row.cityBg .three {
    margin-bottom: 45px;
}

.home .row.cityBg a span {
    text-transform: uppercase;
    width: 100%;
}

.home .fasc-button {
    display: inline-block;
    margin-right: 30px;
}

.home .row.cityBg a span::after {
    content: '';
    display: block;
    position: absolute;
    bottom: -5px;
    width: 10%;
    border-top: 2px solid #fff;
}

.home.page .row h3 {
    color: #fff;
    position: relative;
    width: initial;
    padding: 0px;
    border-radius: 4px;
    text-align: left;
    top: 0px;
    left: 0px;
    height: initial;
    text-transform: uppercase;
    font-weight: normal;
}

.iconList {
    margin-top: 30px;
    width: 100%!important;
}

.iconList li {
    display: inline-block;
    float: left;
    position: relative;
    width: 25%;
}

.iconList div {
    margin: 0 auto;
    text-align: center;
    width: 50%;
}

.iconList li i {
    margin: 0 auto 15px auto;
}

.iconList li span {
    font-size: 20px;
    position: relative;
    text-transform: uppercase;
}

.iconList li span::after {
    border-top: 1px solid #fff;
    content: '';
    display: block;
    margin: 15px auto;
    width: 25px;
}

.overflower {
    height: 300px;
    margin-bottom: 60px;
    width: 95%;
}

.overflower ul {
    width: 50%;
}

/*.industriesweserve .columns.three {*/
    /*margin-bottom: 30px;*/
/*}*/

.home.page .row.home_header2 {
    padding: 0px 45px 45px 45px;
}

.home.page .row.home_header2 h3 {
    width: 70%;
    margin-bottom: 15px;
    display: block;
}

.home.page .row.home_header2 h3:after {
    content: '';
    border: none;
}

.home_header2 h3 .bigone {
    display: block;
    font-size: 50px;
    text-transform: none;
}

.home_header2 h3 .bigtwo {
    display: block;
    font-size: 24px;
    margin-bottom: 30px;
    text-transform: none;
}

.home_header2 h3 .bigthree {
    display: block;
    font-size: 40px;
    text-transform: none;
}

.home_header2 h3 .bigfour {
    display: block;
    font-size: 18px;
    text-transform: none;
}

.home_header2 h3 .bigone,
.home_header2 h3 .bigtwo,
.home_header2 h3 .bigthree,
.home_header2 h3 .bigfour {
    color: #343a4a;
}

.location h5 {
    line-height: 1;
    margin-bottom: 15px;
}

@media screen and (max-width: 768px) {
    .home.page .row.home_header2 {
        padding: 15px;
    }

    .home.page .row.home_header2 .column:last-child {
        margin-top: 45px;
    }

    .home.page .row.home_header2 h3 {
        color: #535352;
        text-align: center;
        width: 100%;
    }

    .home_header2 h3 .bigone {
        font-size: 32px;
        margin-bottom: 5px;
    }

    .home_header2 h3 .bigtwo,
    .home_header2 h3 .bigfour {
        font-size: 12px;
    }

    .home_header2 h3 .bigthree {
        font-size: 26px;
        margin-bottom: 5px;
    }

    .home.page .row.home_header2 h3:after {
        content: '';
        border: none;
    }

    .row ul {
        width: 100%;
    }

    .links .fasc-button {
        margin: 50px 30px;
    }

    .row .jobs {
        width: 100%;
        font-size: 12px;
        margin-right: 10%;
        margin-bottom: 0px;
    }

    .iconList li {
        min-height: 125px;
        width: 50%;
    }

    .iconList div {
        margin: 0 auto;
        width: 100%;
        word-wrap: break-word;
    }

    .iconList li span {
        font-size: 16px;
    }
}