.owl-carousel {
    height: 500px;
    /*position: relative;*/
}

#joinus {
    height: 350px;
}

.owl-carousel div:not(.owl-nav) {
    height: 100%;
}

.owl-carousel .owl-slide {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.owl-carousel div.owl--text {
    position: absolute;
    top: 4em;
    width: 90%;
    height: 8em;
    padding: 1em;
    border-radius: 4px;
    text-align: center;
}

.owl-carousel .owl-nav {
    position: absolute;
    top: 30%;
    width: 100%;
}

.owl-carousel .owl-nav .owl-prev {
    float: left;
}

#homepage .owl-nav .owl-prev {
    display: none;
}

#joinus .owl-prev {
    background-color: #51c1e4;
    height: 50px;
    padding: 6px 5px;
    width: 50px;
    opacity: 1;
}

.owl-carousel .owl-nav div {
    padding: 6px 10px;
    background-color: #7f7f7f;
    opacity: .7;
}

.owl-carousel .owl-nav .owl-next {
    float: right;
}

#homepage .owl-nav .owl-next {
    display: none;
}

#joinus .owl-next {
    background-color: #51c1e4;
    height: 50px;
    width: 50px;
    opacity: 1;
}

.owl-carousel .owl-dots {
    display: none;
}

.owl-carousel .owl-controls {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
}
.owl-carousel .owl-controls .owl-buttons div {
    position: absolute;
    top: 0;
    bottom: 0;
    display: inline-block;
    zoom: 1;
    margin: 0;
    width: 50px;
    height: 30px;
    line-height: 25px;
    text-align: center;
    font-size: .9em;
    border-radius: 3px;
    color: #FFF;
    background: #000;
    opacity: .6;
    text-transform: capitalize;
}
.owl-carousel .owl-controls .owl-buttons .owl-prev {
    left: 5px;
}
.owl-carousel .owl-controls .owl-buttons .owl-next {
    right: 5px;
}

* {
    box-sizing: border-box;
}


@media screen and (max-width: 768px) {
    .owl-carousel div.owl--text {
        left: 0px;
    }

    #joinus {
        min-height: 350px;
    }
}