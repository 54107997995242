footer {
    float: left;
    width: 100%;
}

footer.row {
    padding: 45px 75px;
}

footer.row .row {
    padding: 0px;
}

footer .three.columns {
    position: relative;
    width: 25%;
}

footer h5 {
    line-height: 1;
    text-transform: uppercase;
}

.columns.icon {
    margin-right: 15px;
}

.location address,
.location p {
    font-size: 12px;
    font-style: normal;
    margin-bottom: 3px;
}

.location p {
    font-family: roboto-bold;
}

.location address.last {
    margin-bottom: 15px;
}

.location p.last {
    margin-bottom: 15px;
}

footer a.large {
    color: #535352;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
}

footer a.large:hover {
    color: #51c1e4;
}

footer .columns ul {
    list-style: none;
    width: 100%;
}

footer .columns ul li a::after {
    content: "";
}

footer ul li {
    display: inline-block;
    margin-right: 15px;
}

@media screen and (max-width: 768px) {
    footer.row {
        padding: 15px;
    }

    footer .three.columns {
        width: 50%;
        margin-bottom: 50px;
    }

    footer .nine.columns {
        width: 75%;
    }

    footer .columns ul li {
        margin-right: 15px;
        width: 25%;
    }

    .columns.icon {
        margin-bottom: 15px;
    }
}