.featured-image {
    height: 25%;
    width: 100%;
    object-fit: cover;
}

.page .column h2,
.page .column h3,
.page .column h4,
.page .column h5,
.page .column h6,
.page .column p {
    width: 62.5%;
}

@media screen and (max-width: 768px) {
    .page .column h2,
    .page .column h3,
    .page .column h4,
    .page .column h5,
    .page .column h6,
    .page .column p {
        width: 100%;
    }

    .grey.mob-white {
        color: #fff!important;
    }
}