.mCSB_scrollTools .mCSB_draggerRail {
    background-color: #fff;
    border: 1px solid #51c1e4;
    border-radius: 0px;
    width: 10px;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar:active,
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar:hover {
    background-color: #51c1e4;
    border-radius: 0px;
}

#specialLink {
    background-color: #51c1e4;
    background-image: url(../images/ico_arrow.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 50px 30px;
    color: #fff;
    height: 50px;
    padding: 15px;
    position: absolute;
    right: -45px;
    text-align: center;
    top: 38%;
    width: 50px;
}

#specialLink:hover {

}

@media screen and (max-width: 768px) {
    #specialLink {
        display: none;
    }
}