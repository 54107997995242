#closeRight {
    position: absolute;
    right: 1rem;
    top: 1.5rem;
}

button#showRight {
    float: right;
    font-size: 2rem;
}

@media screen and (max-width: 768px) {
    #showRight {
        color: #000;
        float: right;
        font-size: 40px;
        margin: 0px;
        padding: 15px 0px;
    }

    .cbp-spmenu {
        background-color: #fff;
    }

    .home.page .row .cbp-spmenu h3 {
        background-color: #fff;
        color: #535352;
        font-size: 14px;
        margin: 15px;
        padding: 0px;
        text-align: left!important;
    }

    .home.page .row .cbp-spmenu h3:after {
        border: none;
    }

    .cbp-spmenu span {
        color: #535352;
        font-size: 1.9em;
        padding: 10px;
        margin: 0;
        font-weight: 300;
        background: #fff;
    }

    .cbp-spmenu span:after {
        background: #535352;
    }

    .cbp-spmenu span:before {
        background: #535352;
    }

    #closeRight {
        right: 15px;
        top: 15px;
    }

    .cbp-spmenu .columns {
        padding: 15px;
    }

    .cbp-spmenu-vertical a {
        border: 0px;
        color: #535352;
    }

    .cbp-spmenu ul {
        width: 100%;
    }

    .cbp-spmenu ul li {
        padding: 0px;
    }

    .cbp-spmenu ul li a {
        font-family: roboto-light;
        font-size: 32px;
        line-height: 6rem;
        padding: 0px;
        text-transform: uppercase;
    }
}