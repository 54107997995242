.team .columns.six {
    margin: 1%;
    padding: 0px;
}

.team .columns.six .teamMember {
    height: 100%;
    width: 100%;
}

@media screen and (max-width: 768px) {
    .team .columns.six {
        margin-right: 2%;
        width: 42%;
    }

    .team .columns.six .teamMember {

    }
}