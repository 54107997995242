.page .row.mainheader {
    padding: 0px 15px 0px;
}

.header {
    display: block;
}

.header .nine {
    float: right;
}

.header .menu {
    float: right;
    margin: 0px;
    text-align: right;
    width: 100%;
}

.header .menu li {
    display: inline;
    margin: 0px 0px 0px 2rem;
    position: relative;
    text-align: right;
    text-transform: uppercase;
}

.header .menu li a {
    border: none;
    color: #343a4a;
    font-family: roboto-medium;
    font-size: 14px;
    line-height: 12rem;
    text-decoration: none;
    text-transform: capitalize;
    width: 100%;
}

.header .menu li a:hover {
    color: #51c1e4;
}

.header .menu li.current-menu-item a {
    border-bottom: 1px solid #343a4a;
    font-weight: bold;
    padding-bottom: 5px;
}

.header .menu li a::after {
    content: '';
}

.mobHeader {
    display: none;
}

.bottomPart {
    display: none;
}

@media screen and (max-width: 768px) {
    .header {
        display: none;
    }

    .mobHeader {
        display: block;
    }

    #menu-main li {
        margin-bottom: 15px;
    }

    .cbp-spmenu .twelve ul li {
        display: inline-block;
        float: left;
        width: 25%;
    }
}