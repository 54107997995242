.backgrounds {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 500px;
    width: 100%;
}

.backgrounds.services_header {
    background-image: url('../images/services_header.png');
}

.backgrounds.about_story {
    background-image: url('../images/about_story.png');
}

.backgrounds.homepage {
    background-image: url('../images/alley.png');
}

.backgrounds.alley {
    background-image: url('../images/alley.png');
}

.backgrounds.bean {
    background-image: url('../images/bean.png');
}

.backgrounds.cementbuilding {
    background-image: url('../images/cementbuilding.png');
}

.backgrounds.chi_boat {
    background-image: url('../images/chi_boat.png');
}

.backgrounds.chicagoriverbridge {
    background-image: url('../images/chicagoriverbridge.png');
}

.backgrounds.cloudytrump {
    background-image: url('../images/cloudytrump.png');
}

.backgrounds.construction {
    background-image: url('../images/construction.png');
}

.backgrounds.cornerofbuilding {
    background-image: url('../images/cornerofbuilding.png');
}

.backgrounds.downsteps {
    background-image: url('../images/downsteps.png');
}

.backgrounds.elstop {
    background-image: url('../images/elstop.png');
}

.backgrounds.farmersmarket {
    background-image: url('../images/farmersmarket.png');
}

.backgrounds.fourbuildings {
    background-image: url('../images/fourbuildings.png');
}

.backgrounds.foggychicago {
    background-image: url('../images/foggychicago.png');
}

.backgrounds.guyonsteps {
    background-image: url('../images/guyonsteps.png');
}

.backgrounds.innercornerglassbldg {
    background-image: url('../images/innercornerglassbldg.png');
}

.backgrounds.lowerwacker {
    background-image: url('../images/lowerwacker.png');
}

.backgrounds.marinatowers {
    background-image: url('../images/marinatowers.png');
}

.backgrounds.pulleduptrainbridge {
    background-image: url('../images/pulleduptrainbridge.png');
}

.backgrounds.purplebuildings {
    background-image: url('../images/purplebuildings.png');
}

.backgrounds.purplefourbuildings {
    background-image: url('../images/purplefourbuildings.png');
}

.backgrounds.trees_sky {
    background-image: url('../images/trees_sky.png');
}

.backgrounds.trianglelookup {
    background-image: url('../images/trianglelookup.png');
}

.backgrounds.trump {
    background-image: url('../images/trump.png');
}

.backgrounds.undertheel {
    background-image: url('../images/undertheel.png');
}

.backgrounds.yellowglassbldg {
    background-image: url('../images/yellowglassbldg.png');
}

.backgrounds.home_getintouch {
    background-image: url('../images/home_getintouch.png');
}

.backgrounds.meetourteam_header {
    background-image: url('../images/meetourteam_header.png');
}

.backgrounds.joinus_header {
    background-image: url('../images/joinus_header.png');
}

.backgrounds.home_about {
    background-image: url('../images/home_about.png');
}

.backgrounds.home_getintouch {
    background-image: url('../images/home_getintouch.png');
}

.backgrounds.home_whatwedo {
    background-image: url('../images/home_whatwedo.png');
}

.backgrounds.services_industries {
    background-image: url('../images/services_industries.png');
}

.backgrounds.undecided {
    background-image: url('../images/undecided.png');
}

.backgrounds.home_main {
    background-image: url('../images/home_main.png');
}

.backgrounds.about_story {
    background-image: url('../images/about_story.png');
}

/**
* Finalized backgrounds
*/
.backgrounds.about_header {
    background-image: url('../images/about_header.png');
}

.backgrounds.About_OurStory {
    background-image: url('../images/About_OurStory.png');
}

.backgrounds.About_WhyGenesis {
    background-image: url('../images/About_WhyGenesis.png');
}

.backgrounds.home_header {
    background-image: url('../images/home_header.png');
}

.backgrounds.home_header2 {
    background-image: url('../images/home_header2.png');
}

.backgrounds.home_header2 {
    background-image: url('../images/home_header2.png');
}

.backgrounds.team_header {
    background-image: url('../images/team_header.png');
}

.backgrounds.undecided {
    background-image: url('../images/undecided.png');
}

.backgrounds.find_talent_header {
    background-image: url('../images/FindTalent_header.png');
}

.backgrounds.get_hired_header {
    background-image: url('../images/GetHired_Header.png');
}

.grey-background {
    background-color: rgba(228, 228, 228, .2);
}

@media screen and (max-width: 768px) {
    .backgrounds {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 150px;
        width: 100%;
    }
}