.page .fasc-button {
    border: 1px solid #51c1e4;
    cursor: pointer;
    display: inline-block;
    font-size: 18px;
    margin-right: 30px;
    margin-top: 50px;
    padding: 20px 45px;
    text-transform: uppercase;
}

.page .fasc-button:hover {
    background-color: #343a4a!important;
    border: 1px solid #51c1e4!important;
    color: #fff!important;
}

input[type="email"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
select,
textarea {
    border: none;
    border-bottom: 1px solid #343a4a;
    border-radius: 0px;
    color: #343a4a;
    width: 100%;
}

input[type="email"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="text"]:focus,
input[type="tel"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
textarea:focus,select:focus {
    border: none;
}

input[type="text"]:focus {
    border: none;
    border-bottom: 1px solid #343a4a;
}

input[type="submit"],
button {
    border: none;
    color: #51c1e4;
    float: right;
}

input[type="submit"] {
    float: left;
}

@media screen and (max-width: 768px) {
    .page .fasc-button {
        font-size: 12px;
        padding: 10px 20px;
    }
}

.join-us-form .six.columns {
    margin-right: 2%;
}

label.error {
    color: #f00;
}

input.wpcf7-not-valid {
    border-bottom: 1px solid #f00;
    color: #f00;
}