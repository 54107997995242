.page .row {
    padding: 45px;
    position: relative;
}

.page .row h3 {

}

.page .row h3.headline {
    width: 70%;
    margin: 0% auto;
    display: block;
    padding-top: 10%;
    text-align: center;
    font-size: 30px;
}

.page .row p.subheadline {
    font-family: 'roboto-light', serif;
    font-size: 18px;
    margin: 30px 0px;
    text-align: right;
    width: 70%;
}

.page .row h3.headline::after {
    content: '';
    border: none;
}

.page .row h2 {
    margin-bottom: 60px;
}

.page .row h6 {
    margin-bottom: 60px;
}

.page .row.whattheysay {
    text-align: center;
    padding: 45px 0px;
}

.page .row.whattheysay h5 {
    font-family: roboto-medium;
    margin: 2rem auto 0px;
    text-transform: uppercase;
}

.page .row.whattheysay .owl--text {
    position: relative;
    margin: 0px auto;
    top: 0px;
    width: 65%;
}

.page .row.whattheysay .owl--text p {
    font-size: 24px;
    line-height: 1.2;
    margin-bottom: 34px;
    width: 100%;
}

.page .row.whattheysay .owl--text .author {
    text-align: right;
    font-size: 14px;
    padding-right: 60px;
}

.page p.name {
    text-transform: uppercase;
    text-align: center;
    font-family: "roboto-medium";
    font-size: 20px;
    margin-top: 15px;
    margin-bottom: 7px;
}

.page p.job {
    text-transform: uppercase;
    text-align: center;
    font-size: 14px;
    margin-bottom: 45px;
}

.page .team img.teamMember {
    border: 2px solid #343a4a;
}

.getintouch.page .noheight {
    min-height: 100px;
}

.row .columns.six p,
.row .columns.six h5 {
    width: 90%;
}

.row .columns.five p {
    width: 100%;
}

.getintouch ul {
    margin-top: 15px;
}

.getintouch ul li {
    margin-bottom: 5px;
}

.industriesweserve .iconList li {
    margin-bottom: 40px;
}

.industriesweserve .iconList div {
    width: 80%;
}

@media screen and (max-width: 768px) {
    .header {
        display: none;
    }

    .mobHeader {
        display: block;
        width: 100%;
    }

    .page .row {
        padding: 15px;
    }

    .page .row.whattheysay .owl--text p {
        font-size: 14px;
    }

    .page .row h3.headline {
        font-size: 16px;
    }
}