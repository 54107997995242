.row {
    position: relative;
}

.row .columns {
    position: relative;
}

.row .columns.five {
    padding-right: 2%;
}

.row .columns.seven {
    padding-left: 2%;
}

.location.columns.seven:last-child,
.contact.columns.six:last-child {
    background-color: #fff;
    padding: 2%;
}

.row .three.columns {
    position: relative;
}

.mobile-industries-shifter {
    left: -20px;
    position: relative;
}

@media screen and (max-width: 768px) {
    .row .columns.seven {
        padding: 0px;
    }

    .row .columns.two {
        float: left;
        width: 15%;
    }

    .row .columns.ten {
        float: left;
        width: 75%;
    }

    .mobile-industries-shifter {
        left: 0px;
    }

    .columns.nine {
        width: 74%;
    }

    .location.columns.seven:last-child {
        background: none;
        color: #fff!important;
    }

    .location.columns.seven .columns.six h5,
    .location.columns.seven .columns.six p {
        color: #fff!important;
    }

    .location .columns.six {
        width: 50%;
    }
}