.icon {
    display: block;
    height: 50px;
    width: 50px;
}

.largeIcon {
    display: block;
    height: 100px;
    width: 100px;
}

.smallIcon {
    display: block;
    height: 37.5px;
    width: 37.5px;
}

.icon.mapmarker {
    background-image: url('../images/ico_location.svg');
    width: 34px;
}

.icon.mail::after,
.icon.mapmarker::after {
    content: '';
    display: block;
    position: absolute;
    bottom: -5px;
    width: inherit;
    border-top: 1px solid #343a4a;
}

.icon.mail::after {
    left: 7px;
    width: 50%;
}

.icon.white::after {
    border-top: 1px solid #fff;
}

.smallIcon.instagram,
.largeIcon.instagram,
.icon.instagram {
    background-image: url('../images/ico_instagram.svg');
}

.smallIcon.facebook,
.largeIcon.facebook,
.icon.facebook  {
    background-image: url('../images/ico_facebook.svg');
}

.smallIcon.linkedin,
.largeIcon.linkedin,
.icon.linkedin  {
    background-image: url('../images/ico_linkedin.svg');
}

.smallIcon.twitter,
.largeIcon.twitter,
.icon.twitter  {
    background-image: url('../images/ico_twitter.svg');
}

.smallIcon.ecommerce,
.largeIcon.ecommerce,
.icon.ecommerce  {
    background-image: url('../images/ico_ecommerce.svg');
}

.smallIcon.vr,
.largeIcon.vr,
.icon.vr  {
    background-image: url('../images/ico_virtual.svg');
}

.smallIcon.insurance,
.largeIcon.insurance,
.icon.insurance  {
    background-image: url('../images/ico_insurance.svg');
}

.smallIcon.healthcare,
.largeIcon.healthcare,
.icon.healthcare  {
    background-image: url('../images/ico_healthcare.svg');
}

.smallIcon.financial,
.largeIcon.financial,
.icon.financial  {
    background-image: url('../images/ico_financial.svg');
}

.smallIcon.software,
.largeIcon.software,
.icon.software  {
    background-image: url('../images/ico_software.svg');
}

.smallIcon.consulting,
.largeIcon.consulting,
.icon.consulting {
    background-image: url('../images/ico_consulting.svg');
}

.largeIcon.skills,
.icon.skills {
    background-image: url('../images/ico_skills.svg');
}

.smallIcon.chemistry,
.largeIcon.chemistry,
.icon.chemistry {
    background-image: url('../images/ico_chemistry.svg');
}

.smallIcon.culture,
.largeIcon.culture,
.icon.culture {
    background-image: url('../images/ico_culture.svg');
}

.smallIcon.work,
.largeIcon.work,
.icon.work {
    background-image: url('../images/ico_work.svg');
}

.largeIcon.arrow,
.smallIcon.arrow,
.icon.arrow {
    background-image: url('../images/ico_arrow.svg');
}

.largeIcon.mail,
.smallIcon.mail,
.icon.mail {
    background-image: url('../images/ico_mail.svg');
    height: 32px;
}

.rotate-left {

}

.rotate-right {
    transform: rotate(180deg);
}

@media screen and (max-width: 768px) {
    .icon {
        height: 25px;
        width: 25px;
    }

    .largeIcon {
        height: 50px;
        width: 50px;
    }

    .icon.mapmarker {
        height: 37px;
        width: 25px;
    }

    .icon.mapmarker::after {
        bottom: -20px;
    }

    .icon.mail {
        height: 25px;
        width: 39px;
    }
}