body {
    background-color: #fff;
    color: #535352;
    font-family: 'roboto-light', serif;
    font-size: 16px;
    height: 100%;
    line-height: 18px;
    margin: 0;
}

a {
    color: #51c1e4;
    font-family: roboto-bold;
    text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
    color: #343a4a;
}

h1, h2, h3, h4, h6 {
    text-transform: uppercase;
}

h1 {
    background-image: url('../images/logo.png');
    background-repeat: no-repeat;
    background-size: contain;
    height: 125px;
    margin: 0px;
    text-indent: -9999px;
    width: 250px;
}

h2 {
    font-size: 30px;
}

h2::after {
    content: '\25CF\000A\000A\25CF';
    display: block;
    position: absolute;
    font-size: 25px;
}

h3 {
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
    position: relative;
}

h3::after {
    content: '';
    display: block;
    position: absolute;
    bottom: -5px;
    width: 45px;
    border-top: 1px solid #343a4a;
}

h4 {
    font-family: roboto-bold;
    font-size: 18px;
}

h6 {
    font-family: roboto-medium;
    line-height: 1.3;
}

p {
    clear: both;
    line-height: 20px;
}

ul {
    list-style: none;
}

ul li {
    color: #535352;
    text-transform: uppercase;
}

ul li a {
    text-transform: none;
}

.white,
.white h2,
.white h3,
.white h4,
.white h5,
.white h6,
.white p,
.white li,
h2.white,
h3.white,
h4.white,
h5.white,
h6.white,
p.white {
    color: #fff!important;
}

.grey,
.grey h2,
.grey h3,
.grey h4,
.grey h5,
.grey h6,
.grey p,
.grey li,
h2.grey,
h3.grey,
h4.grey,
h5.grey,
h6.grey,
p.grey,
li.grey {
    color: #cdcccc!important;
}

.dark-grey,
.dark-grey h2,
.dark-grey h3,
.dark-grey h4,
.dark-grey h5,
.dark-grey h6,
.dark-grey p,
.dark-grey li,
h2.dark-grey,
h3.dark-grey,
h4.dark-grey,
h5.dark-grey,
h6.dark-grey,
p.dark-grey,
li.dark-grey {
    color: #535352!important;
}

.dark-blue,
.dark-blue h2,
.dark-blue h3,
.dark-blue h4,
.dark-blue h5,
.dark-blue h6,
.dark-blue p,
.dark-blue li,
h2.dark-blue,
h3.dark-blue,
h4.dark-blue,
h5.dark-blue,
h6.dark-blue,
p.dark-blue,
li.dark-blue {
    color: #343a4a!important;
}

.center-text {
    text-align: center;
}

.row {
    padding: 0px 15px;
}

.row .column {
    position: relative;
}

.row.odd {
    background-color: rgba(205, 204, 204, 0.2);
}

.no-padding {
    padding: 0px!important;
}

@font-face {
    font-family: 'roboto-black';
    src: url('../content/fonts/Roboto-Black.ttf'); /* IE9 Compat Modes */
    src: url('../content/fonts/Roboto-Black.ttf')  format('truetype');
}

@font-face {
    font-family: 'roboto-black-italic';
    src: url('../content/fonts/Roboto-BlackItalic.ttf'); /* IE9 Compat Modes */
    src: url('../content/fonts/Roboto-BlackItalic.ttf')  format('truetype');
}

@font-face {
    font-family: 'roboto-bold';
    src: url('../content/fonts/Roboto-Bold.ttf'); /* IE9 Compat Modes */
    src: url('../content/fonts/Roboto-Bold.ttf')  format('truetype');
}

@font-face {
    font-family: 'roboto-bold-condensed';
    src: url('../content/fonts/Roboto-BoldCondensed.ttf'); /* IE9 Compat Modes */
    src: url('../content/fonts/Roboto-BoldCondensed.ttf')  format('truetype');
}

@font-face {
    font-family: 'roboto-bold-condensed-italic';
    src: url('../content/fonts/Roboto-BoldCondensedItalic.ttf'); /* IE9 Compat Modes */
    src: url('../content/fonts/Roboto-BoldCondensedItalic.ttf')  format('truetype');
}

@font-face {
    font-family: 'roboto-italic';
    src: url('../content/fonts/Roboto-Italic.ttf'); /* IE9 Compat Modes */
    src: url('../content/fonts/Roboto-Italic.ttf')  format('truetype');
}

@font-face {
    font-family: 'roboto-light';
    src: url('../content/fonts/Roboto-Light.ttf'); /* IE9 Compat Modes */
    src: url('../content/fonts/Roboto-Light.ttf')  format('truetype');
}

@font-face {
    font-family: 'roboto-light-italic';
    src: url('../content/fonts/Roboto-LightItalic.ttf'); /* IE9 Compat Modes */
    src: url('../content/fonts/Roboto-LightItalic.ttf')  format('truetype');
}

@font-face {
    font-family: 'roboto-medium';
    src: url('../content/fonts/Roboto-Medium.ttf'); /* IE9 Compat Modes */
    src: url('../content/fonts/Roboto-Medium.ttf')  format('truetype');
}

@font-face {
    font-family: 'roboto-medium-italic';
    src: url('../content/fonts/Roboto-MediumItalic.ttf'); /* IE9 Compat Modes */
    src: url('../content/fonts/Roboto-MediumItalic.ttf')  format('truetype');
}

@font-face {
    font-family: 'roboto-regular';
    src: url('../content/fonts/Roboto-Regular.ttf'); /* IE9 Compat Modes */
    src: url('../content/fonts/Roboto-Regular.ttf')  format('truetype');
}

@font-face {
    font-family: 'roboto-thin';
    src: url('../content/fonts/Roboto-Thin.ttf'); /* IE9 Compat Modes */
    src: url('../content/fonts/Roboto-Thin.ttf')  format('truetype');
}

@font-face {
    font-family: 'roboto-thin-italic';
    src: url('../content/fonts/Roboto-ThinItalic.ttf'); /* IE9 Compat Modes */
    src: url('../content/fonts/Roboto-ThinItalic.ttf')  format('truetype');
}

@media screen and (max-width: 500px) {
    h1 {
        float: left;
        height: 75px;
        width: 155px;
    }

    .buttons .columns.six {
        width: 48%;
    }

    .buttons .columns.six:first-child {
        margin-right: 4%;
    }
}
